.hover_underline:hover {
    text-decoration: underline;
}
.XButton {
    float: right;
    margin: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}
.XButton img {
    width: 100%;
    height: 100%;
}
.XButton:hover {
    background-color: lightgray;
}
.feed {
    height: 100%;
    overflow-y: overlay;
    background: inherit;

    background-color: var(--secondarycolor);
}
.feed.notloaded {
    background: #f6f7f8;
    background: linear-gradient(to right,#ffffff 0%, #e9e9e9 50%, #ffffff 100%);
    background-size: 200%;
    animation: placeHolderShimmer 2s infinite linear;
}
.feeditem {
    width: calc(100% - 2rem);
    padding-top: 0.1px;
    margin: 1rem;
    height: fit-content;
    background-color: var(--primarycolor);
    
    border: 1px transparent solid;
    border-width: 0 0 1px 0;
    border-radius: 0.15rem;
    user-select: none;
    
    word-break: keep-all;
    box-shadow: var(--shadow)
}
.feeditem.new {
    border-left-width: 5px;
    border-left-color: var(--themecolor);
}
.feeditem.joinlast {
    margin-top: 0;
    border-top: 0px;
}
.feeditem.joinnext {
    margin-bottom: 0 !important;
    /*border-bottom-width: 0;*/
}
.feeditem.fit {
    display: inline-block;
    width: fit-content;
    vertical-align: middle;
    margin-bottom: 0;
    margin-right: 0;
    border: none;
    overflow: hidden;
}
.feeditem.notloaded {
    height: 40rem;
    background: #f6f7f8;
    background: linear-gradient(to right,#ffffff 0%, #e9e9e9 50%, #ffffff 100%);
    background-size: 200%;
    animation: placeHolderShimmer 2s infinite linear;
}

@keyframes placeHolderShimmer{
    0%{
        background-position-x: 100%;
    }
    100%{
        background-position-x: -100%;
    }
}
.ContextBar {
    display: flex;
    margin: 0 0 -.5rem 0;
    text-decoration: none;
}
.ContextBar a {
    overflow: hidden;
    max-width: 25rem;
    min-width: 0;
}
.ContextBar a:nth-child(2) {
    flex-shrink: 50;
}
.PostTitle {
    margin: 1rem;
    font-size: 1.4rem;
}
.PriceTag {
    display: inline-block;
    float: right;
    font-size: 1rem;
}
.PostDescription, .PostContent {
    margin: 1rem;
}
.ReactionBar {
    width: 100%;
}
.ReactionButton {
    display: inline-block;
    width: 50%;
    
    padding: .5rem;
    
    user-select: none;
    cursor: pointer;
}
.ReactionButton:hover {
    background-color: lightgray;
}

.RelativeDeadline {
    width: fit-content;
    margin: 1rem;
    padding-right: .5rem;
    padding-left: .25rem;
    line-height: 2rem;
    background-color: rgb(186, 238, 175);
    border-left: .5rem solid rgb(84, 201, 80);
    border-radius: .2rem;
}
.RelativeDeadline.late {
    background-color: rgb(233, 233, 233);
    border-left: .5rem solid rgb(143, 143, 143);
}
.RelativeDeadline.close {
    background-color: rgb(250, 242, 139);
    border-left: .5rem solid rgb(240, 223, 74);
}
.RelativeDeadline .texticon {
    float: left;
    margin: .25rem .25rem .25rem 0;
    height: 1.5rem;
    width: 1.5rem;
}
.welcome .feeditem {
    background: #39b135;
    background: linear-gradient(to right,#50c94c 0%, var(--themecolor) 50%, #50c94c 100%);
    background-size: 200%;
    animation: placeHolderShimmer 10s infinite linear;
}

.UserLink , .GroupLink, .TypeLink{
    white-space: nowrap;
    overflow-x: hidden;
    max-width: 100%;

    display: inline-block;
    vertical-align: middle;
    line-height: 2rem;
    margin-top: -1px;
    margin-bottom: -1px;
    padding:  .3rem .5rem .3rem .3rem;
    border-radius: 0 0 .2rem .2rem;
    
    transition: .2s;
}
.TypeLink {
    border-radius: 0 0 .2rem 0;
    padding: .3rem 1rem .3rem 1rem;
}
.UserLink.inactive:hover {
    background-color: unset;
}
.UserLink img , .GroupLink img{
    margin-left: 0;
}
.UserLink:hover, .GroupLink:hover, .TypeLink:hover {
    background-color: var(--secondarycolor);
}

.SmallTitle 
{
    margin: .5rem 1rem .5rem 1rem;
    line-height: 2rem;
    font-weight: bold;
}
.BoldTitle 
{
    font-weight: bold;
}
.JumpButton {
    position: fixed;
    text-align: center;
    width: fit-content;
    top: 4rem;
    left: 0;
    right: 0;
    margin: auto;
    
    z-index: 30;
    transition: .3s;
}
.JumpButton:hover {
    background-color: lightblue;
}
.JumpButton.hidden {
    top: -10rem
}

.Separator {
    width: 100%;
    border: 1px solid var(--secondarycolor);
    border-width: 2px 0 0 0;
}

.searchBar {
    display: inline-block;
    margin-left: 1rem;
    margin-bottom: -1px;
    margin-top: -1px;
}
/*
.searchBar input[type="image"] {
    vertical-align: middle;
    padding: .5rem;
    width: 3rem;
    transition: .2s;
}
*/
.searchBar input[type="image"]:hover {
    background-color: var(--secondarycolor);
}
.searchBar .deleteButton {
    margin-bottom: -.5rem;
    width: 1.5rem !important;
    height: 1.5rem;
    padding: .2rem;
    cursor: pointer;
    transition: .2s;
}
.searchBar .deleteButton:hover {
    background-color: var(--secondarycolor);
}
.searchInput {
    width: 8rem;
    border-width: 0 0 1px 0;
}

.TimeBar {
    font-size: .8rem;
}
.FeedText, .FeedTitle {
    margin: 1rem;
    font-weight: 700;
    color: rgb(97,97,97)
}
.FeedTitle {
    font-size: 1.2rem;
    margin-bottom: 0;
}
