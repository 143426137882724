.LanguageConfig
{   
    height: calc(100% - 11rem);
    overflow: scroll;
    background-color: var(--primarycolor);
    box-shadow: var(--shadow);
    
    margin: 1rem;
    padding: .5rem;
}
.LanguageConfig textarea
{   
    height: fit-content;
}
.LanguageConfig thead 
{
    font-weight: 700;
}
.LanguageConfig input {
    height: 2rem;
}
.LanguageConfig td {
    vertical-align: top;
    border-collapse: collapse;
    min-width: 5rem;
    width: 40%;
}
.LanguageConfig td:first-child {
    width: unset;
    max-width: 10rem;
    word-wrap: break-word;
}