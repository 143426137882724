.checkmarkcontainer {
    display: inline-block;
    line-height: 1rem !important;
    visibility: visible;
    width: 2rem;
    height: 2rem;
    background-color: var(--secondarycolor);
    margin: 1rem;
    
    border-radius: .2rem;
    
    transition: 1s;
    z-index: -10;
}
@keyframes load {
    0%{
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.checkmarkcontainer .checkmark {
    background: none;
    
    display: inline-grid;
    position: relative;
	left: 10px;
	top: 2px;
	width: 10px;
	height: 21px;
    
    border: 4px solid;
    border-collapse: separate; 
    border-width: 3px;
    border-color: var(--rarecolor);
    border-left-color: transparent !important;
    border-top-color: transparent !important;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
    transform: rotate(45deg);
    
    transition: .1s;
}

.checkmarkcontainer.load .checkmark {

    animation: load .5s linear infinite;
    top: 5px;
    left: 5px;
	width: 21px;

    border-radius: 10px;
}

.checkmarklabel {
    display: inline-grid;
    height: 2rem;
    line-height: 2rem;
    margin: 1rem 1rem 1rem 0;
}

/*checkmark*/
.checkmarkcontainer .true {
    border-color: rgb(43, 145, 56);
}
/*container*/
.checkmarkcontainer.true {
    background-color:  rgb(113, 212, 126);
}

.checkmarkcontainer .false {
    border-color:  var(--primarycolor);
}
.checkmarkcontainer:hover .checkmark{
    visibility: visible;
    filter: opacity(.7);
}
.checkmarkcontainer:hover{
    filter: opacity(.7);
    cursor:pointer;
}

.checkmarkcontainer .loadercontainer {
    width: 1rem;
    height: 1rem;
    margin: .5rem !important;
}
