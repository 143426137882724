.helpcontainer {
    float: right;
    width: 3rem;
    height: 3rem;
    padding: 0;
    
}

.helpicon {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    margin: .75rem;
    
    text-align: center;
    font-size: 14px;
    line-height: 1.6rem;
    
    color: white;
    background-color: rgb(61, 59, 59);
    border-radius: 1rem;
    box-shadow: var(--basicshadow);
    
    transition: .2s;
    user-select: none;
    cursor: pointer;
}
.helpicon:hover {
    filter: opacity(.8);
}
.help {
    margin-bottom: 1rem;
}


