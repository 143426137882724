
/* custom variables */
:root {
  --basicshadow: rgba(0, 0, 0, 0.14) 0px 0px 4px, rgba(0, 0, 0, 0.28) 0px 4px 8px;
  --darkshadow: 0px 0px 20px #1b1b1b;
  --buttonshadow: 0px 0px 10px #5f5e5e;
  --blueshadow: 0px 0px 40px #4a5099;
  --lightshadow: 2px 2px 10px #c7c7c9;
  
  --bordercolor: rgb(177, 177, 177);
  --themecolor: #2ba359;
  
  --primarycolor: #f2f4f2;
  --secondarycolor: #DFDFDF;
  --minorcolor: rgb(72, 80, 146);
  --rarecolor: #118945;
  --buttoncolor:#4C4CD5;

  --deletecolor: rgb(199, 64, 64);

  --shadow: #c1c1c1 2px 2px 7px 0px;
}
/* scrollbar */
/* width */
::-webkit-scrollbar {
    width: .5rem;
}
/* Track */
::-webkit-scrollbar-track {
    background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.349);
    border-radius: .5rem;
    
    transition: .5s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    
}

/* overlay modification */
.styles_overlay-wrapper__O0Zm8 .styles_overlay-content__1qZR_ {
    padding: 0 !important;
}

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.2); }


@keyframes shake {
  0% {left: 0;}
  50% {left: -.4rem;}
  100% {left: 0;}
}

#root {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
}

body {
    font-size: 16px;
    color: black !important;
    background-color: var(--secondarycolor);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    overflow-y: none !important;
    overflow-x: hidden !important;
}
body, input, button {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
} 
/*
input[type="button"] {
    display: inline-block;
    height: 2.5rem;
    padding: .4rem 1rem .4rem 1rem;
    
    margin: auto;
    
    font-size: 1rem;
    color: white;
    background-color:rgb(89, 89, 241);
    border: none;
    border-radius: .2rem;
    cursor: pointer;
    transition: .2s;
}
input[type="button"]:hover {
    filter: opacity(.7);
}
input[type="button"].red {
    background-color: var(--deletecolor);
    color: white
}
*/
span {
  white-space: pre-line;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*
@font-face {
    font-family: "Roboto";
    src: local('Pacifico Regular'), url("./media/Roboto-Light.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Roboto-bold";
    src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap'), url("./media/Roboto-Light.ttf");
    font-display: swap;
}*/


h2 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
h3 {
    font-weight: lighter;
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

input {
    border-color: var(--bordercolor);
}
big {
    font-size: 18px;
}
.IconParameter {
    margin-bottom: 1rem;
}
.IconParameter .texticon {
    vertical-align: top;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
}
.IconParameter .text {
    display: inline-block;
    word-wrap: break-word;
    vertical-align: middle;
}