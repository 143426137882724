

.Overlay {
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    z-index: 4000;
    width: 100%;
    height: 100%;

    background-color: rgba(87, 87, 87, 0.705);

    animation: .2s ease-in-out 0s 1 fadein;
}
.OverlayContent.invisible {
    background: none;
    box-shadow: none;
}

.OverlayContent .feeditem {
    box-shadow: none
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.OverlayContent {
    width: var(--content);
    max-width: 100%;
    max-height: 80%;
    overflow-y: overlay;

    background-color: var(--primarycolor);
    box-shadow: 0px -1px 20px 0 rgb(90, 90, 90);
    border-radius: .15rem;
}

.OverlayContent .CloseButton {
    display: block;
    position: relative;
    float: right;
    margin-top: .4rem;
    margin-right: .5rem;
    border-radius: .2rem;
    overflow: hidden;
}

.OverlayContent.invisible .CloseButton{
    margin-top: 1.5rem;
    margin-right: 1.5rem;
}