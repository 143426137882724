.calendar {
    margin-bottom: -1px;
}

.calendarheader {
    height: 3.5rem;
    text-align: center;
}

.calendar .month {
    height: 3.5rem;
    width: 20rem;
    max-width: 50%;
    line-height: 3.5rem;
    font-size: 1.5rem;
    
    margin: 0 5% 0 5%;
}
.calendarheader div {
    display: inline-block;
}

.calendar .day {
    display: inline-block;
    width: calc(100% / 7);
    height: 6rem;
    
    vertical-align: top;
    margin: 0;
    padding: .5rem;
    
    line-height: 1rem;
    
    border: .5px solid lightgray;
    border-width: 0 1px 1px 0;
    transition: .1s;
    user-select: none;
}

.calendar span:nth-child(7n + 1) .day{
    border-right-width: 0;
}
/*
.calendar .day.inactive {
    background-color: var(--secondarycolor)
}*/
.calendar .day.active {
    background-color: var(--primarycolor);
}
.calendar .day.today {
    background-color: rgb(207, 247, 207);
}

.calendar .day:not(.empty).active:hover {
    cursor: pointer;
    background-color: lightgray;
}

.calendar .navigationbutton {
    display: inline-block;
    position: relative;
    left: 0;
    width: 3.5rem;
    height: 3.5rem;
    --padding: 1rem;
    padding: var(--padding);
    vertical-align: top;

    background: none;
    border: none;
    transition: .2s;
    cursor: pointer;
    user-select: none;
}

.calendar .navigationbutton:hover {
    position: relative;
    transform: translateX(-.3rem);
}

.calendar .navigationbutton.right {
    transform: rotate(180deg);
}
.calendar .navigationbutton.right:hover {
    position: relative;
    transform: rotate(180deg) translateX(-.3rem);
}

.calendar .dayname {
    display: inline-grid;
    width: calc(100% / 7);
    height: 2rem;
    
    text-align: center;
    line-height: calc(2rem - 2px);
    
    background-color: var(--minorcolor);
    color: white;
}
.datetitle {
    font-size: 2rem;
}
.eventicon {
    float: left;
    --width: 1.2rem;
    width: var(--width);
    height: var(--width);
    margin: .1rem;

    background-color: black;
    border-radius: .65rem;

    text-align: center;
    color: white;
    font-size: .75rem;
    line-height: 1.3rem;
    font-weight: 500;
}

/* when displayer out of calendar in text conext */
.eventicon {
    margin-right: .5rem;
    --width: 1rem;
}
.day .eventicon {
    margin: .1rem;
    --width: 1.2rem;
}
.eventicon.event {
    --color: blue;
}
.eventicon.event_applied, .eventicon.delivery_date{
    --color: rgb(68, 165, 68);
}
.eventicon.event_deadline {
    --color: red;
}
.eventicon.order_deadline, 
.eventicon.purchase_date, 
.eventicon.delivery_date {
    border-radius: .25rem;
}
.eventicon.order_deadline {
    --color: red;
}
.eventicon.purchase_date {
    --color: rgb(212, 212, 87);
}
.eventicon {
    background-color: var(--color);
}
.own .eventicon{
    border: 3px solid var(--color);
    background-color: unset;

    color: black;
    line-height: calc(1.3rem - 6px);
}
.eventpanel {
    transition: .2s;
}
.eventpanel:hover {
    cursor: pointer;
    background-color: rgb(214, 214, 214);
}