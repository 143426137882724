.form {
    width: calc(100%-4rem);
    margin: 1rem;
    
    border-radius: 1rem;
    background-color: inherit;
    color: black;
}
input, select, textarea {
    font-family: "Roboto";
    font-size: medium;
    background-color: inherit;
}
.custom-select {
    position: relative;
    width: auto;
    height: 2rem;
    /*margin: 1rem 1rem 0 0;
    */
    border: 1px solid var(--bordercolor);
    border-width: 0 0 1px 0;
    background-color: transparent;
    padding-left: .4rem;
    
    transition: .2s;
}
input:focus, select:focus, textarea:focus {
    outline: none;
}
.flag {
    display: inline-block;
    margin: 1rem 0 1rem 0;
    color: rgb(56, 56, 56);
    font-size: .9rem;
    
}
.formtitle {
    font-size: 1.5rem;
    margin-bottom: 0rem !important;
    text-align: left;
    font-weight: lighter;
}
.textinput {
    width: 100%;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    height: 2rem;
    font-size: 1rem;
    border-radius: 0;
    border: 2px solid var(--bordercolor);
    border-width: 0 0 1px 0;
    transition: .2s;
}

.numberinput {
    width: 5rem;
}
.wronginput {
    border-color: red;
    border-width: 0 0 2px 0;
    background-color: rgb(252, 225, 225);
}
input[type="number"] {
    width: 6rem;
}
.short {
    width: 12rem !important;
}
.line {
    min-width: 100%;
}
.line.opening {
    animation: open 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    --animationHeight: 6rem;
}
.line.closing {
    min-width: 100%;
    animation: close .2s ease-in;
}
.line.tall {
    --animationHeight: 20rem;
}
@keyframes open {
    0% {
        max-height: 0;
    }
    100% {
        max-height: var(--animationHeight)
    }
}
@keyframes close {
    0% {
        max-height: var(--animationHeight);
        opacity: 1;
    }
    100% {
        max-height: 0;
        opacity: 0;
    }
}
.right {
    display: inline-block;
    width: 60%;
    padding: .5rem;
}
.message {
    color: red;
    font-size: 1rem;
    margin-top: 1rem !important;
}

input[type="submit"] {
    display: inline-block;
    height: 2.5rem;
    padding: .4rem 1rem .4rem 1rem;
    
    margin: auto;
    
    font-size: 1rem;
    color: white;
    background-color:rgb(89, 89, 241);
    border: none;
    border-radius: .15rem;
    cursor: pointer;
    transition: .2s;
}
input[type="submit"] .buttonicon {
    height: 100%;
}
input[type="submit"]:hover {
    filter: opacity(.8);
}
input[type="submit"]:disabled {
    background-color: rgb(190, 190, 190);
    cursor: default;
}

textarea {
    min-height: 10rem;
    height: fit-content;
    width: 100%;
    resize: vertical;
    border-width: 1px !important; 
}
.date {
    width: auto;
}

/* star input */
.rating {
    display: inline-block;
    position: relative;
    height: 2rem;
    width: fit-content;
    line-height: 2rem;
    font-size: 2rem;
}

.rating label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
}

.rating label:last-child {
    position: static;
}

.rating label:nth-child(1) {
    z-index: 5;
}

.rating label:nth-child(2) {
    z-index: 4;
}

.rating label:nth-child(3) {
    z-index: 3;
}

.rating label:nth-child(4) {
    z-index: 2;
}

.rating label:nth-child(5) {
    z-index: 1;
}

.rating label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.rating label .staricon {
    float: left;
    color: transparent;
}

.rating label:last-child .staricon {
    color: var(--secondarycolor);
}

.rating:not(:hover) label input:checked ~ .staricon,
.rating:hover label:hover input ~ .staricon {
    color: var(--rarecolor);
}

.rating label input:focus:not(:checked) ~ .staricon:last-child {
    color: #000;
    text-shadow: 0 0 5px rgb(230, 230, 94);
}